import "survey-core/defaultV2.min.css";
import {
  Model,
  SvgRegistry,
  Serializer,
  FunctionFactory,
  JsonObject,
  ComponentCollection,
} from "survey-core";
import { Survey } from "survey-react-ui";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  fetchFromAPI,
  postResultsToAPI,
  fetchFishImageFromAPI,
} from "../../utils/hooks.js";
import surveyTheme from "survey-core/themes/plain-light-panelless";
import "./SurveyPage.css";
import LocationQuestions from "../../survey/locationQuestions.js";
import MissingWaterQuestions from "../../survey/missingWaterQuestions.js";
import SurveyQuestions from "../../survey/surveyQuestions.js";
import PostSurveyQuestions from "../../survey/postSurveyQuestions.js";
import { v4 as uuidv4 } from "uuid";
import Loader from "../Loader.js";
import { registerLocationPicker } from "../../survey/LocationPickerModel.js";
import { registerFishCarousel } from "../../survey/FishCarouselModel.js";
import { ReactComponent as InfoIcon } from "../../images/icons8-info.svg";
import ReactDOMServer from "react-dom/server";
import ReCAPTCHA from "react-google-recaptcha";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import config from "../../config";
import CatchFishExample from "../../images/CatchFishExample.png";
import GOIDExample from "../../images/License_GOID_Highlight.png";
import { Typography } from "@mui/material";
import PilotBanner from "../PilotBanner.js";
/**
 * Represents the SurveyPage component.
 * This component is responsible for rendering and managing the survey page.
 * It handles the state of various survey-related variables and interacts with the API to fetch necessary data.
 * The component also includes functions for handling user interactions and updating the survey data.
 */
function SurveyPage() {
  const dialogs = Object.freeze({
    CatchFish: "catchFish",
    FishedFrom: "fishedFrom",
    GOID: "goid",
  });

  const [isLoading, setLoading] = useState(true);
  const [isLocationReady, setIsLocationReady] = useState(false);
  const [isLocationSelected, setLocationSelected] = useState(false);
  const [showMissingWaterSurvey, setShowMissingWaterSurvey] = useState(false);
  const [currentBasemap, setCurrentBasemap] = useState(0);
  const [isSurveyCompleted, setSurveyCompleted] = useState(false);
  const [isPostSurveyCompleted, setPostSurveyCompleted] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [waterBodies, setWaterBodies] = useState({});
  const [surveyConfig, setSurveyConfig] = useState({});
  const [pageOneSubmission, setPageOneSubmission] = useState({});
  const [startSurveyTime, setStartSurveyTime] = useState(0);
  const [open, setOpen] = useState(false);
  const [dialogOption, setDialogOption] = useState();
  const [fishImageMap, setFishImageMap] = useState(new Map());

  const handleClickOpen = (dialog) => {
    setDialogOption(dialog);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setStartSurveyTime(Date.now());
    Promise.all([fetchFromAPI("WaterConfig"), fetchFromAPI("SurveyConfig")])
      .then(([WaterBodies, SurveyConfig]) => {
        setWaterBodies(WaterBodies);
        setSurveyConfig(SurveyConfig);
        setLoading(false);
      })
      .catch((error) => console.log("Error", error));
  }, []);

  const locationSurvey = useMemo(() => {
    const locationJson = LocationQuestions({
      data: waterBodies.result,
      basemapIndex: currentBasemap,
    });
    return new Model(locationJson);
  }, [waterBodies, currentBasemap]);

  useEffect(() => {
    registerLocationPicker();
    registerFishCarousel();

    // Add a property to the a Choice element
    Serializer.addProperty("itemvalue", {
      name: "locations",
    });

    // Add a property to the a Choice element
    Serializer.addProperty("itemvalue", {
      name: "latitude",
    });

    // Add a property to the a Choice element
    Serializer.addProperty("itemvalue", {
      name: "longitude",
    });

    locationSurvey.applyTheme(surveyTheme);
    locationSurvey.showNavigationButtons = false;

    locationSurvey.onChoicesLazyLoad.add((_, options) => {
      if (
        options.question.getType() === "dropdown" &&
        options.question.name === "location-dropdown"
      ) {
        const regex = /[^\w]/g; // remove all non-alphanumeric characters

        let startsWithWaters = waterBodies.result.waters.filter((water) =>
          water.text
            .replace(regex, "")
            .toLowerCase()
            .startsWith(options.filter.replace(regex, "").toLowerCase())
        );

        let includesWaters = waterBodies.result.waters.filter(
          (water) =>
            !startsWithWaters.includes(water) &&
            water.text
              .replace(regex, "")
              .toLowerCase()
              .includes(options.filter.replace(regex, "").toLowerCase())
        );

        let filteredWaters = startsWithWaters.concat(includesWaters);

        let choices = filteredWaters.slice(
          options.skip,
          options.skip + options.take
        );

        setTimeout(() => {
          options.setItems(choices, filteredWaters.length);
        });
      }
    });

    locationSurvey.onGetChoiceDisplayValue.add((_, options) => {
      if (
        options.question.getType() === "dropdown" &&
        options.question.name === "location-dropdown"
      ) {
        const valuesStr = options.values[0];
        const waterText = waterBodies.result.waters.find((water) => {
          return water.value === valuesStr;
        }).text;

        options.setItems([waterText]);
      }
    });

    locationSurvey.onValueChanged.add((sender, options) => {
      if (options.question.name === "location-dropdown") {
        let selectedItem = options.question.choices.find(
          (x) => x.value === options.value
        );
        const mapLocationPicker = sender.getQuestionByName("location-map");
        if (selectedItem) {
          mapLocationPicker.setPropertyValue(
            "selectedLocation",
            JSON.parse(JSON.stringify(selectedItem.locations)) // deep copy to prevent issues with the original data
          );
        } else {
          // clear the question if location is cleared
          mapLocationPicker.setPropertyValue("selectedLocation", null);
          mapLocationPicker.value = {};
        }
      } else if (options.question.name === "location-map") {
        setIsLocationReady(Object.keys(options.value).length !== 0);
      }
    });
  }, [locationSurvey, waterBodies]);

  const missingWaterSurvey = useMemo(() => {
    const missingWaterJson = MissingWaterQuestions({
      basemapIndex: currentBasemap,
      data: surveyConfig.result,
    });
    return new Model(missingWaterJson);
  }, [surveyConfig, currentBasemap]);

  const showMissingWaterForm = useCallback(
    (show) => {
      const locationSurveyMap =
        locationSurvey.getQuestionByName("location-map");
      const missingWaterSurveyMap =
        missingWaterSurvey.getQuestionByName("missing-waters-map");

      if (show) {
        // currently showing Location Survey, so switch to Missing Water Survey
        const basemapIndex = locationSurveyMap.getPropertyValue("basemapIndex");
        missingWaterSurveyMap.setPropertyValue("basemapIndex", basemapIndex);
      } else {
        // currently showing Missing Water Survey, so switch to Location Survey
        const basemapIndex =
          missingWaterSurveyMap.getPropertyValue("basemapIndex");
        locationSurveyMap.setPropertyValue("basemapIndex", basemapIndex);
      }

      setShowMissingWaterSurvey(show);
    },
    [locationSurvey, missingWaterSurvey]
  );

  useEffect(() => {
    missingWaterSurvey.applyTheme(surveyTheme);
    missingWaterSurvey.showNavigationButtons = false;

    resetMissingWaterMap();

    // Set the default location to the center of California
    function resetMissingWaterMap() {
      const mapLocationPicker =
        missingWaterSurvey.getQuestionByName("missing-waters-map");
      mapLocationPicker?.setPropertyValue("selectedLocation", [
        {
          latitude: 36.778259,
          longitude: -119.417931,
          zoom: 5,
        },
      ]);
    }

    missingWaterSurvey.onGetPageTitleActions.add((_, options) => {
      options.titleActions = [
        {
          id: "show-description",
          iconName: "icon-left",
          title: "Cancel",
          action: (action) => {
            showMissingWaterForm(false);
          },
        },
      ];
    });

    missingWaterSurvey.onValueChanged.add((sender, options) => {
      if (options.question.name === "mw-county-dropdown") {
        let selectedItem = options.question.choices.find(
          (x) => x.value === options.value
        );
        const mapLocationPicker =
          sender.getQuestionByName("missing-waters-map");
        mapLocationPicker.value = {};
        if (selectedItem) {
          mapLocationPicker.setPropertyValue("selectedLocation", [
            {
              latitude: selectedItem.latitude,
              longitude: selectedItem.longitude,
              zoom: 10,
            },
          ]);
        } else {
          resetMissingWaterMap();
        }
      }
    });
  }, [missingWaterSurvey, showMissingWaterForm]);

  const survey = useMemo(() => {
    const surveyJson = SurveyQuestions({
      uuid: uuidv4(),
      fishImageMap: fishImageMap,
      deviceUsed: navigator.userAgentData
        ? navigator.userAgentData.platform
        : navigator.platform,
      data: surveyConfig.result,
    });
    return new Model(surveyJson);
  }, [surveyConfig, fishImageMap]);

  useEffect(() => {
    survey.applyTheme(surveyTheme);
    survey.showNavigationButtons = false;

    SvgRegistry.registerIconFromSvg(
      "icon-info",
      ReactDOMServer.renderToString(<InfoIcon />)
    );

    if (surveyConfig.result && !Serializer.findClass("fishfinder")) {
      ComponentCollection.Instance.add({
        name: "fishfinder",
        // An array of JSON schemas that configure the nested questions
        elementsJSON: [
          {
            name: "FishSpecies",
            titleLocation: "hidden",
            type: "dropdown",
            isRequired: true,
            choices: surveyConfig.result.fish,
          },
          {
            type: "image",
            visibleIf: "{CatchFish} = Yes",
            name: "fishCaughtImage",
            width: "50%",
            minWidth: "224px",
            imageFit: "cover",
            imageHeight: "auto",
            imageWidth: "1000",
          },
        ],
        onLoaded(question) {
          setFishImage(
            config.DEFAULT_FISH_IMAGE_ID,
            question.contentPanel.getQuestionByName("fishCaughtImage")
          );
        },
        onValueChanged(question, name, fishId) {
          if (name === "FishSpecies" && fishId) {
            setFishImage(
              fishId,
              question.contentPanel.getQuestionByName("fishCaughtImage")
            );
          }
        },
        onPropertyChanged(question, name, isAnswered) {
          if (name === "isAnswered" && !isAnswered) {
            setFishImage(
              config.DEFAULT_FISH_IMAGE_ID,
              question.contentPanel.getQuestionByName("fishCaughtImage")
            );
          }
        },
      });
    }

    survey.onGetQuestionTitleActions.add((survey, options) => {
      if (options.question.name === "LocationName") {
        options.titleActions = [
          {
            id: "show-description",
            iconName: "icon-left",
            title: "Back",
            action: (action) => {
              const geolocationPicker = survey.getQuestionByName("geolocation");
              geolocationPicker.setPropertyValue("selectedLocation", null);
              geolocationPicker.value = {};

              setCurrentBasemap(
                geolocationPicker.getPropertyValue("basemapIndex")
              );

              setRecaptchaValue(null);
              setLocationSelected(false);
            },
          },
        ];
      } else if (options.question.name === "CatchFish") {
        options.titleActions = [
          {
            id: "show-description",
            iconName: "icon-info",
            action: () => {
              handleClickOpen(dialogs.CatchFish);
            },
          },
        ];
      } else if (options.question.name === "FishedFrom") {
        options.titleActions = [
          {
            id: "show-description",
            iconName: "icon-info",
            action: () => {
              handleClickOpen(dialogs.FishedFrom);
            },
          },
        ];
      }
    });

    survey.onValueChanged.add((_, options) => {
      if (options.question?.name === "WhatSpecies") {
        options.value.forEach((fishId) => {
          if (!fishImageMap.has(fishId)) {
            fetchFishImageFromAPI(fishId)
              .then((fishImage) => {
                setFishImageMap((f) => f.set(fishId, fishImage));
                survey.render();
              })
              .catch((error) => console.log(error));
          }
        });
      }
    });

    function validateFishCaughtValues(_, options) {
      if (options.name === "fish-table") {
        if (
          survey.getQuestionByName("CatchFish").value === "Yes" &&
          (survey.getQuestionByName("fish-caught").value === undefined ||
            survey.getQuestionByName("fish-caught").value.length === 0) &&
          (options.value === undefined || options.value.length === 0)
        ) {
          options.error = "Please add at least one fish to the catch list.";
        }

        if (options.value === undefined || options.value.length === 0) return;
        let fishKept = options.value[0].FishKept;
        let fishReleased = options.value[0].FishReleased;

        fishKept = isNaN(fishKept) ? 0 : fishKept;
        fishReleased = isNaN(fishReleased) ? 0 : fishReleased;

        if (fishKept + fishReleased === 0) {
          options.error =
            "Please enter a positive value for fish kept or released.";
        }
      }
    }

    survey.onValidateQuestion.add(validateFishCaughtValues);

    survey.onMatrixRowAdding.add(function (survey, options) {
      options.allow = false;
      if (!survey.getQuestionByName("fish-table").hasErrors()) {
        const fishCaught = survey.getQuestionByName("fish-caught");
        let val = fishCaught.value;
        if (!Array.isArray(val)) val = [];
        let qData = survey.getQuestionByName("fish-table").getPlainData()
          .data[0];

        console.log("QDATA", qData);
        let dataToAdd = {
          displayValue: qData.displayValue.FishFinder.FishSpecies,
          value: qData.value.FishFinder.FishSpecies,
          FishSize: qData.value.FishSize,
          FishKept: qData.value.FishKept ? qData.value.FishKept : 0,
          FishReleased: qData.value.FishReleased ? qData.value.FishReleased : 0,
        };
        val.push(dataToAdd);
        fishCaught.value = val;
        options.question.value = undefined;
      }
    });

    function setFishImage(fishId, question) {
      if (fishImageMap.has(fishId)) {
        if (question) question.imageLink = fishImageMap.get(fishId);
      } else {
        if (question && fishImageMap.has(config.DEFAULT_FISH_IMAGE_ID)) {
          question.imageLink = fishImageMap.get(config.DEFAULT_FISH_IMAGE_ID);
        }
        fetchFishImageFromAPI(fishId)
          .then((fishImage) => {
            setFishImageMap((f) => f.set(fishId, fishImage));
            if (question) question.imageLink = fishImage;
          })
          .catch((error) => console.log(error));
      }
    }

    // Predownload the default fish image
    setFishImage(config.DEFAULT_FISH_IMAGE_ID);
  }, [
    survey,
    dialogs.CatchFish,
    dialogs.FishedFrom,
    fishImageMap,
    surveyConfig.result,
  ]);

  const postSurvey = useMemo(() => {
    const postSurveyJson = PostSurveyQuestions({
      data: pageOneSubmission.result,
    });
    return new Model(postSurveyJson);
  }, [pageOneSubmission]);

  useEffect(() => {
    postSurvey.applyTheme(surveyTheme);
    postSurvey.showNavigationButtons = false;

    const getFishProperty = function (params) {
      return this.row.item.info[params[0]];
    };

    JsonObject.metaData.addClass(
      "itemvalues_info",
      [
        {
          name: "info",
        },
      ],
      null,
      "itemvalue"
    );

    var choicesProp = JsonObject.metaData.findProperty(
      "matrixdropdown",
      "rows"
    );
    choicesProp.className = "itemvalues_info";

    FunctionFactory.Instance.register("getFishProperty", getFishProperty);

    postSurvey.onGetQuestionTitleActions.add((survey, options) => {
      if (options.question.name === "goid") {
        options.titleActions = [
          {
            id: "show-description",
            iconName: "icon-info",
            action: () => {
              handleClickOpen(dialogs.GOID);
            },
          },
        ];
      }
    });
  }, [postSurvey, dialogs.GOID]);

  const submitLocation = () => {
    const locationMap = locationSurvey.getQuestionByName("location-map");
    const locationSelected = locationMap.value;
    const basemapIndex = locationMap.getPropertyValue("basemapIndex");

    const geolocationPicker = survey.getQuestionByName("geolocation");

    survey.getQuestionByName("LocationName").value = locationSelected.text;
    survey.getQuestionByName("location").value = locationSelected.value;

    let locationArray = [];
    locationArray.push(locationSelected);
    geolocationPicker.setPropertyValue("selectedLocation", locationArray);
    geolocationPicker.setPropertyValue("basemapIndex", basemapIndex);
    geolocationPicker.visible = true;

    setLocationSelected(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
    });

    // clear the map location picker
    const mapLocationPicker = locationSurvey.getQuestionByName("location-map");
    mapLocationPicker.setPropertyValue("selectedLocation", null);
    mapLocationPicker.value = {};

    locationSurvey.clear();
    missingWaterSurvey.clear();
  };

  const submitMissingWater = () => {
    if (missingWaterSurvey.validate(true, true)) {
      const locationSelected =
        missingWaterSurvey.getQuestionByName("missing-waters-map").value;
      const locationName =
        missingWaterSurvey.getQuestionByName("mw-water-name").value;
      const countyName =
        missingWaterSurvey.getQuestionByName("mw-county-dropdown").value;
      const locationDescription = missingWaterSurvey.getQuestionByName(
        "mw-water-description"
      ).value;
      const contactPhone =
        missingWaterSurvey.getQuestionByName("mw-phone").value;
      const contactName = missingWaterSurvey.getQuestionByName("mw-name").value;
      const contactEmail =
        missingWaterSurvey.getQuestionByName("mw-email").value;

      survey.getQuestionByName("LocationName").value = locationName;
      survey.getQuestionByName("location").value = "99999";
      survey.getQuestionByName("geolocation").value = locationSelected;
      survey.getQuestionByName("MissingCounty").value = countyName;
      survey.getQuestionByName("MissingDescription").value =
        locationDescription;
      survey.getQuestionByName("PhoneNumber").value = contactPhone;
      survey.getQuestionByName("AnglerName").value = contactName;
      survey.getQuestionByName("AnglerEmail").value = contactEmail;

      survey.getQuestionByName("geolocation").visible = false;

      console.log("Survey Data", survey.getPlainData());
      setLocationSelected(true);
      setShowMissingWaterSurvey(false);
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
      locationSurvey.clear();
      missingWaterSurvey.clear();
    }
  };

  const submitSurvey = () => {
    survey.getQuestionByName("fish-table").addRow();

    let fishTable = survey.getQuestionByName("fish-table");
    let fishfinder = fishTable
      .getNestedQuestions()
      .find((q) => q.name === "FishSpecies");

    fishTable.columns.forEach((col) => {
      col.isRequired = false;
    });
    fishfinder.isRequired = false;

    survey.getQuestionByName("SurveyDuration").value = Math.round(
      (Date.now() - startSurveyTime) / 1000
    );

    setOtherValue(survey, "Gear", "GearOther");

    let surveyData = survey
      .getPlainData()
      .filter(
        (item) =>
          !["fish-table", "totalFish", "totalKept", "totalReleased"].includes(
            item.name
          )
      )
      .map(({ title, displayValue, isNode, data, ...rest }) => rest);

    if (survey.getQuestionByName("CatchFish").value === "No")
      surveyData = surveyData.filter((el) => el.name !== "fish-caught");

    if (survey.validate(true, true)) {
      console.log("Survey Data", surveyData);
      setLoading(true);
      postResultsToAPI("LoadPage1Data", surveyData, {
        "X-Recaptcha-Token": recaptchaValue,
      }).then((response) => {
        setPageOneSubmission(response);
        survey.doComplete();
        setSurveyCompleted(true);
        setLoading(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      });
    } else {
      fishTable.columns.forEach((col) => {
        col.isRequired = true;
      });
      fishfinder.isRequired = true;
    }
  };

  const submitPostSurvey = () => {
    setOtherValue(postSurvey, "Travel", "TravelOther");
    setOtherValue(postSurvey, "Gender", "GenderOther");
    setOtherValue(postSurvey, "Race", "RaceOther");
    setOtherValue(postSurvey, "Language", "LanguageOther");

    let postSurveyData = postSurvey
      .getPlainData()
      .map(({ title, displayValue, isNode, data, ...rest }) => rest);

    if (postSurvey.validate(true, true)) {
      console.log("postSurvey Data", postSurveyData);
      setLoading(true);
      postResultsToAPI("LoadPage2Data", postSurveyData).then((response) => {
        postSurvey.doComplete();
        setPostSurveyCompleted(true);
        setLoading(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      });
    }
  };

  const setOtherValue = (survey, questionName, otherQuestionName) => {
    let otherQuestion = survey
      .getQuestionByName(questionName)
      .getPlainData()
      .data.find((gear) => gear.value === "other");

    survey.getQuestionByName(otherQuestionName).value = otherQuestion
      ? otherQuestion.displayValue
      : null;
  };

  var dialogRender = (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogOption === dialogs.CatchFish && "What did you catch?"}
        {dialogOption === dialogs.FishedFrom && "Where did you fish from?"}
        {dialogOption === dialogs.GOID && "GOID"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogOption === dialogs.CatchFish && (
            <>
              Please enter a row for each species and size combination caught.
              If you caught fish of different sizes but the same species, please
              enter a new row for each species/size combination.
              <div className="dialog-image">
                <img
                  style={{ maxWidth: "100%" }}
                  alt="Fish caught example"
                  src={CatchFishExample}
                />
              </div>
            </>
          )}
          {dialogOption === dialogs.FishedFrom && (
            <>
              Motorized Boat:
              <ul>
                <li>electric or gas motor</li>
              </ul>
              Nonmotorized Boat:
              <ul>
                <li>float tube</li>
                <li>kayak</li>
                <li>drift boat</li>
                <li>paddle board</li>
                <li>etc.</li>
              </ul>
            </>
          )}
          {dialogOption === dialogs.GOID && (
            <>
              You can find your GOID on your fishing license here:
              <div className="dialog-image">
                <img
                  style={{ maxWidth: "100%" }}
                  alt="GOID license example"
                  src={GOIDExample}
                />
              </div>
            </>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );

  var surveyRender = isLoading ? (
    <Loader />
  ) : waterBodies.success && !isLocationSelected ? (
    <>
      {showMissingWaterSurvey === true ? (
        <>
          <Survey model={missingWaterSurvey} showCompletedPage={false} />
          <div className="btn-submit">
            <button
              className="sd-btn sd-btn--action sd-navigation__complete-btn"
              onClick={submitMissingWater}
            >
              NEXT
            </button>
          </div>
        </>
      ) : (
        <>
          <Survey model={locationSurvey} showCompletedPage={false} />
          {isLocationReady && (
            <div className="location-note">
              Now that you've selected where you fished, please take the survey
              to let us know what you've caught.
            </div>
          )}
          <div className="btn-submit">
            <button
              className="sd-btn sd-btn--action sd-navigation__complete-btn"
              disabled={!isLocationReady}
              onClick={submitLocation}
            >
              NEXT
            </button>
            <br />
            <br />
            <Button
              variant="outlined"
              onClick={() => showMissingWaterForm(true)}
            >
              Can’t find your water?
            </Button>
          </div>
        </>
      )}
    </>
  ) : surveyConfig.success && !isSurveyCompleted ? (
    <>
      <Survey model={survey} showCompletedPage={false} />
      <div className="btn-submit">
        <ReCAPTCHA
          className="recaptcha"
          sitekey={"6LcCnPYpAAAAABtfKIhS-LNzIF2UpxImN0AA8w10"}
          onChange={(value) => setRecaptchaValue(value)}
        />
        <button
          className="sd-btn sd-btn--action sd-navigation__complete-btn"
          disabled={recaptchaValue === null}
          onClick={submitSurvey}
        >
          Submit
        </button>
      </div>
    </>
  ) : isSurveyCompleted ? (
    <div className="survey-complete-text">
      {!isPostSurveyCompleted && (
        <>
          <Typography
            variant="h1"
            textAlign="center"
            sx={{
              fontSize: "3rem",
              fontWeight: "bold",
              paddingTop: "2rem",
              color: () => "#045070",
            }}
          >
            Survey Submitted
          </Typography>
          <Typography
            textAlign="center"
            sx={{
              fontSize: "1.2rem",
            }}
          >
            Thank you for submitting your survey data, please enter optional
            demographic information below.
          </Typography>

          <div className="btn-submit">
          <button
            className="sd-btn sd-btn--action sd-navigation__complete-btn"
            onClick={submitPostSurvey}
          >
            Skip Optional Data 
          </button>
          </div>

        </>
      )}
      <Survey model={postSurvey} showCompletedPage={true} />

      {isPostSurveyCompleted ? (
        <div className="end-survey">
          <div className="btn-submit">
            <Button
              variant="contained"
              href="https://wildlife.ca.gov/Fishing/Inland/CIRAS/Contact"
              sx={{ padding: "20px 40px" }}
            >
              Send CIRAS Feedback to CDFW
            </Button>
          </div>
          <div className="btn-submit">
            <Button
              variant="contained"
              sx={{ padding: "20px 40px" }}
              component={Link}
              to={"/"}
            >
              Return Home
            </Button>
          </div>
        </div>
      ) : (
        <div className="btn-submit">
          <button
            className="sd-btn sd-btn--action sd-navigation__complete-btn"
            onClick={submitPostSurvey}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  ) : (
    <div>
      <h3>Error</h3>
      <h5>An error occured. Please try again.</h5>
    </div>
  );

  return (
    <div id="survey-page" className="section collapsed">
      <div className="container">
        {!isPostSurveyCompleted && !isSurveyCompleted && (
          <div className="page-title">
            <Typography
              variant="h1"
              sx={{
                fontSize: "3rem",
                fontWeight: "bold",
                paddingTop: "2rem",
                color: () => "#045070",
              }}
            >
              Angler Survey
            </Typography>
            <PilotBanner></PilotBanner>
            <Typography
              textAlign="center"
              sx={{
                fontSize: "1.2rem",
              }}
            ><h3 class="h5 bold">
              Each entry is for a single angler, on single body of water, on a
              single day. <br/>Submitting data to CIRAS is not a substitute for
              species specific report cards (steelhead, Chinook, Sturgeon).
              </h3>
            </Typography>
          </div>
        )}
      </div>
      {surveyRender}
      {dialogRender}
    </div>
  );
}

export default SurveyPage;
