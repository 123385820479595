const dev = {
  API: "https://aw1-dev-fnapp-ciras-1.azurewebsites.net/api/ExecuteSqlProcedure?code=K03XCu2wWfHBb2OHbCxHo5z1nhxaCRRp1tiFvY7i6GSoAzFuhdceGQ==",
  POWER_BI_API:
    "https://aw1-dev-fnapp-ciras-1.azurewebsites.net/api/GetPowerBiToken?code=Vm0yISduxs06HlSpoeNYEVGVLlSqvERvSmoLaT3mUi9tAzFuYSzsDQ%3D%3D",
  FISH_REPORT: "b2ce3ed1-d568-440c-aa03-c7236fa8eee5",
  WATERBODY_REPORT: "a8adc78d-4f74-4386-9bad-2516e897b1ca",
  AREA_REPORT: "4ec0058b-200a-422d-996c-cfea3572e636",
  STAGE: "DEV",
  STAGE_NAME: "development",
};

const test = {
  API: "https://aw1-test-fnapp-ciras-1.azurewebsites.net/api/ExecuteSqlProcedure?code=xA2msJD6oSf5BNigbyyrlffIuCk58ZXERUZjt4MbTePQAzFuM9tQDg%3D%3D",
  POWER_BI_API:
    "https://aw1-test-fnapp-ciras-1.azurewebsites.net/api/GetPowerBiToken?code=5wZcvB91tusGxYtzUcpppfwxuMLRaCXZURoskRsM1KstAzFudyRAmw%3D%3D",
  FISH_REPORT: "6a8efbc4-859b-4650-83df-0d9e4a49cc08",
  WATERBODY_REPORT: "bca419f4-1616-4c97-890d-380bcd18f02d",
  AREA_REPORT: "bebd8cde-41e5-423c-8933-38830aeda746",
  STAGE: "TEST",
  STAGE_NAME: "test",
};

const prod = {
  API: "https://aw1-ciras-prod-fnapp.azurewebsites.net/api/ExecuteSqlProcedure?code=s6qZTBwDhXebmVBhxZ9MZyi25p05dDsOGtJ_78dcaWmdAzFuislpSg%3D%3D",
  POWER_BI_API:
    "https://aw1-ciras-prod-fnapp.azurewebsites.net/api/GetPowerBiToken?code=N3aNVcelZn6qWDM-bHU1b1XOj8xSKa8iBVZe0KDhZN-jAzFuaz0PVw%3D%3D",
  FISH_REPORT: "e9ce8f45-108e-433a-ab84-412b49429424",
  WATERBODY_REPORT: "a6d9db6b-4b9c-4c28-97f3-bf6a8075cb2b",
  AREA_REPORT: "df6848b7-e13b-41c3-8ad2-d693b5295e51",
  STAGE: "PROD",
  STAGE_NAME: "production",  
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case "production":
    config = prod;
    break;
  case "test":
    config = test;
    break;
  case "development":
  default:
    config = dev;
}

config.DEFAULT_FISH_IMAGE_ID = 67;
config.YOUTUBE_VIDEO_ID = "HHKms-qnt9E";

export default config;
